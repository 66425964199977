// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-fr-js": () => import("./../../../src/pages/contacts-fr.js" /* webpackChunkName: "component---src-pages-contacts-fr-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-contacts-pt-js": () => import("./../../../src/pages/contacts-pt.js" /* webpackChunkName: "component---src-pages-contacts-pt-js" */),
  "component---src-pages-gallery-fr-js": () => import("./../../../src/pages/gallery-fr.js" /* webpackChunkName: "component---src-pages-gallery-fr-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gallery-pt-js": () => import("./../../../src/pages/gallery-pt.js" /* webpackChunkName: "component---src-pages-gallery-pt-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index-fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index-pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-location-fr-js": () => import("./../../../src/pages/location-fr.js" /* webpackChunkName: "component---src-pages-location-fr-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-location-pt-js": () => import("./../../../src/pages/location-pt.js" /* webpackChunkName: "component---src-pages-location-pt-js" */),
  "component---src-pages-prices-fr-js": () => import("./../../../src/pages/prices-fr.js" /* webpackChunkName: "component---src-pages-prices-fr-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-prices-pt-js": () => import("./../../../src/pages/prices-pt.js" /* webpackChunkName: "component---src-pages-prices-pt-js" */)
}

